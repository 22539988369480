* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: Arial, myriad-pro,  sans-serif;
    color: rgba(0, 0, 0, .80);
}

.container {
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

/**
  Link
**/

a {
    color: #2098f3;
    text-decoration: none;
    word-wrap: break-word;
    cursor: pointer;
}

a:hover {
    color: #40a9ff;
    text-decoration: none;
}

a.btn-primary:hover {
    text-decoration: none;
    background-color: #40a9ff;
    border-color: #40a9ff;
    color: #fff;
}

/**
  Button
**/

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 0 20px;
    font-size: 14px;
    border-radius: 4px;
    height: 45px;
    line-height: 45px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    position: relative;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border-color: #e8e8e8;
    outline: none;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-lg {
    padding: 0 30px;
}

.btn-gr {
    padding: 0 30px;
    height: 50px;
    font-size: large;
}

.btn-primary {
    color: #fff;
    background-color: #2098f3;
    border-color: #2098f3;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.btn-success {
    color: #fff;
    background-color: #52c41a;
    border-color: #52c41a;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
    background-color: #52c41a;
    border-color: #52c41a;
}

.btn-link {
    border: none;
    height: 34px;
    padding: 0 15px;
}

.btn-link:hover {
    background-color: rgba(158, 158, 158, 0.20);
}

.active.focus,
.active:focus,
.focus,
:active.focus,
:active:focus,
:focus {
    outline: none !important;
}

.btn.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/**
  Custom
**/

/*.custom-container {*/
    /*min-height: calc(100vh - 150px);*/
/*}*/

.custom-content {
    /*margin-top: 10px;*/
    padding: 35px;
}

@media screen and (min-width: 1200px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

/**
  Form
**/

.form-container {
    text-align: center;
    /*min-height: calc(100vh - 135px);*/
}

.form-content {
    background: #fff;
    /*box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);*/
    border-radius: 2px;
    display: block;
    margin: auto auto 0;
    margin-top: 30px;
    vertical-align: middle;
    position: relative;
    padding: 35px;
}

.form-content-fit {
    min-width: 100px;
    max-width: 800px;
    width: 100%;
}

@media (max-width: 768px) {
    h1 {
        font-size: 1.7em;
    }
}

.form-item {
    margin-bottom: 18px;
    align-items: baseline;
}

.form-item .btn {
    cursor: pointer;
}

.form-item label {
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, .65);
}

.form-control {
    margin: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 45px;
    font-size: 0.87em;
    line-height: 45px;
    color: rgba(0, 0, 0, .80);
    background-color: #fff;
    background-image: none;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    transition: all .3s;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.form-control:hover, .form-control:focus, .form-control:active {
    border-color: #40a9ff;
    outline: 0;
    border-right-width: 1px !important;
}

.form-control:focus, .form-control:active {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
}

.form-control.invalid {
    border-color: #f5222d;
}

.form-control.invalid:focus, .form-control.invalid:active {
    box-shadow: 0 0 0 2px rgba(245, 34, 45, .2);
}

.form-control[disabled], fieldset[disabled] .form-control {
    cursor: not-allowed;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #f6f8fa;
    opacity: 1;
}

.form-label {
    margin-bottom: 10px;
}

.form-title {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.65);
}

/**
  Or separator
**/

.or-separator {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 1em;
}

.or-text {
    position: absolute;
    left: 46%;
    top: 0;
    background: #fff;
    padding: 10px;
    color: rgba(0, 0, 0, .45);
}


.block-separator {
    border-bottom: 1px solid #eee;
    padding: 11px 0;
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 1em;
}

.block-separator-text {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    padding: 10px;
    color: rgba(0, 0, 0, .45);
}

.hide {
    display: none;
}

.required:after {
    content: ' *';
    color: #ff0000;
}

.upper {
    text-transform: uppercase;
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1
}

.flexFlowCol {
    display: flex;
    flex-flow: column nowrap;
}

.flexFormTitle {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.flexFlowRow {
    display: flex;
    flex-flow: row nowrap;
}

.fgrow {
    flex-grow: 1
}

.fwrap {
    flex-flow: wrap
}

.fItemAlignCenter {
    align-items: center;
}

.fFlexStart {
    justify-content: flex-start;
}

.fFlexEnd {
    justify-content: flex-end;
}

.fSpaceBetween {
    justify-content: space-between;
}

.fCenter {
    justify-content: center;
}

.fshrink {
    flex-shrink: 1
}

/* TEXT-ALIGN */
.taleft {
    text-align: left
}

.taright {
    text-align: right
}

.tacenter {
    text-align: center
}

/* FLOAT */
.fleft {
    float: left
}

.fright {
    float: right
}

.cboth {
    clear: both
}

/* MARGIN */
.m0 {
    margin: 0;
}

.m5 {
    margin: 5px;
}

.m10 {
    margin: 10px;
}

.m15 {
    margin: 15px;
}

.m20 {
    margin: 20px;
}

.m25 {
    margin: 25px;
}

.m30 {
    margin: 30px;
}

.mauto {
    margin: auto;
}

/* MARGIN-TOP */
.mt0 {
    margin-top: 0;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt15 {
    margin-top: 15px;
}

.mt20 {
    margin-top: 20px;
}

.mt25 {
    margin-top: 25px;
}

.mt30 {
    margin-top: 30px;
}

/* MARGIN-BOTTOM */
.mb0 {
    margin-bottom: 0;
}

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb25 {
    margin-bottom: 25px;
}

.mb30 {
    margin-bottom: 30px;
}

/* MARGIN-LEFT */
.ml0 {
    margin-left: 0;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml15 {
    margin-left: 15px;
}

.ml20 {
    margin-left: 20px;
}

.ml25 {
    margin-left: 25px;
}

.ml30 {
    margin-left: 30px;
}

.ml35 {
    margin-left: 35px;
}

/* MARGIN-RIGHT */
.mr0 {
    margin-right: 0;
}

.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px;
}

.mr15 {
    margin-right: 15px;
}

.mr20 {
    margin-right: 20px;
}

.mr25 {
    margin-right: 25px;
}

.mr30 {
    margin-right: 30px;
}

.mr35 {
    margin-right: 35px;
}

/* PADDING */
.p0 {
    padding: 0;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p15 {
    padding: 15px;
}

.p20 {
    padding: 20px;
}

.p25 {
    padding: 25px;
}

.p30 {
    padding: 30px;
}

/* PADDING-TOP */
.pt0 {
    padding-top: 0;
}

.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt15 {
    padding-top: 15px;
}

.pt20 {
    padding-top: 20px;
}

.pt25 {
    padding-top: 25px;
}

.pt30 {
    padding-top: 30px;
}

.pt35 {
    padding-top: 35px;
}

/* PADDING-BOTTOM */
.pb0 {
    padding-bottom: 0;
}

.pb5 {
    padding-bottom: 5px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb15 {
    padding-bottom: 15px;
}

.pb20 {
    padding-bottom: 20px;
}

.pb25 {
    padding-bottom: 25px;
}

.pb30 {
    padding-bottom: 30px;
}

.pb35 {
    padding-bottom: 35px;
}

/* PADDING-LEFT */
.pl0 {
    padding-left: 0;
}

.pl5 {
    padding-left: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pl15 {
    padding-left: 15px;
}

.pl20 {
    padding-left: 20px;
}

.pl25 {
    padding-left: 25px;
}

.pl30 {
    padding-left: 30px;
}

.pl35 {
    padding-left: 35px;
}

/* PADDING-RIGHT */
.pr0 {
    padding-right: 0;
}

.pr5 {
    padding-right: 5px;
}

.pr10 {
    padding-right: 10px;
}

.pr15 {
    padding-right: 15px;
}

.pr20 {
    padding-right: 20px;
}

.pr25 {
    padding-right: 25px;
}

.pr30 {
    padding-right: 30px;
}

.pr35 {
    padding-right: 35px;
}

/* COLOR */
.cdanger {
    color: #d9534f
}

.cgrey {
    color: #949494
}

.cblue {
    color: #1890ff
}

.csuccess {
    color: #5CB85C
}
.bgwhite {
    background: #fff;
}

.tag {
    font-size: small;
    color: #949494;
}

/* TEXT */
.bold {
    font-weight: bold
}

.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.inner-custom-card:hover {
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
}
.inner-custom-card:hover .custom-card {
    cursor: pointer;
}

.border-bottom {
    border-bottom: 1px solid #eee;
}

.btn-large {
    width: 250px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    font-size: 24px;
    justify-content: center;
    align-items: center;
}

.main-logo {
    width: 100%;
    height: 100%;
    max-width: 800px;
}

.hidden {
    display: none;
}

.ant-upload-picture-card-wrapper {
    width: auto;
}

.ant-layout-header {
    background: rgb(28,54,100);
}
.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    background: rgb(28,54,100);

}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: #d2bc70;
}