.form-login {
    max-width: 500px;
}

.social-btn {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
}

.social-btn img {
    height: 32px;
    float: left;
    margin-top: 10px;
}

.social-btn.icon img {
    height: 24px;
    margin-left: 3px;
}

.signup-link {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
}
