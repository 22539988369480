.home-container {
    text-align: center;
    overflow: auto;
    position: relative;
    padding-top: 60px;
}

.home-title {
    color: rgba(0,0,0,0.70);
    text-transform: uppercase;
    margin-top: 50px; 
}
