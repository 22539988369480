ol {
    padding-top: 15px!important;
    list-style: none;
    display: flex;
    align-items: center;
}

.breadcrumb-separator {
    color: #333;
    margin: auto 6px;
    user-select: none;
}